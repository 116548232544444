import { message } from 'antd';
import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import { Modal } from 'antd';
import Api from '../api/api';
import { LoadingOutlined, } from '@ant-design/icons';

const PaymentList = () => {
    const api = new Api();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const token = localStorage.getItem('token');

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [data, setData] = useState([]);
    const [dataSeleted, setDataSeleted] = useState({});

    const showModal = (item) => {
        setIsModalOpen(true);
        setDataSeleted(item);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        else {
            fetchAPI()
        }
        // eslint-disable-next-line
    }, [])

    const fetchAPI = () => {
        setLoading(true);

        api.getPaymentListVt(token)
            .then(respose => {
                setLoading(false);
                if (respose.status === 'ok') {
                    setData(respose.data.payments);
                } else {
                    messageApi.error('Error intenta de nuevo.');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error: ', error);
                messageApi.error('Error de conexión.');
            });

    };

    return (
        <div className='w-screen h-screen'>
            {contextHolder}
            <Helmet><title>Transferencias | Rayo</title></Helmet>
            <Header />
            <div className=' w-screen h-[calc(100vh-80px)] bg-[#F4F4F4] overflow-auto px-10 flex flex-col'>
                <h1 className='text-black font-semibold text-3xl pt-7'>Transferencias</h1>
                {
                    loading ?
                        <LoadingOutlined />
                        :
                        data.map((item) => (
                            <>
                                {
                                    item.type === 'payment_remittance_trade' ?
                                        <div onClick={() => showModal(item)} key={item.id} className='w-full h-auto bg-white hover:bg-slate-200 rounded-2xl mt-3 mb-3 flex'>
                                            <div className='w-1/2'>
                                                <p className='text-black font-semibold text-2xl ml-10 mt-3 mb-2'>De {item.first_name} {item.last_name}</p>
                                                <p className='text-black font-semibold text-xl ml-10 mt-1 mb-1'>ACH Deposit</p>
                                                <p className='text-black text-xl ml-10 mt-1 mb-3'>{item.application_date}</p>
                                            </div>
                                            <div className='w-1/2 h-auto flex items-center justify-end'>
                                                <p className='text-black font-semibold text-4xl mr-10'>${item.amount - (item.amount / 100) * 3} USD</p>
                                            </div>
                                        </div>
                                        :
                                        <div onClick={() => showModal(item)} key={item.id} className='w-full h-auto bg-white hover:bg-slate-200 rounded-2xl mt-3 mb-3 flex'>
                                            <div className='w-1/2'>
                                                <p className='text-black font-semibold text-2xl ml-10 mt-3 mb-2'>Para {item.currency === 'USD' ? item.first_name + ' ' + item.last_name : item.full_name}</p>
                                                <p className='text-black font-semibold text-xl ml-10 mt-1 mb-1'>{item.currency === 'USD' ? 'ACH Deposit USD' : 'SPEI Deposit MXN'}</p>
                                                <p className='text-black text-xl ml-10 mt-1 mb-3'>{item.application_date}</p>
                                            </div>
                                            <div className='w-1/2 h-auto flex items-center justify-end'>
                                                <p className='text-black font-semibold text-4xl mr-10'>-${item.amount_to_send} {item.currency === 'USD' ? 'USD' : 'MXN'}</p>
                                            </div>
                                        </div>
                                }
                            </>
                        ))}
            </div>
            <Modal title="Transferencia" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {
                    dataSeleted.type === 'payment_remittance_trade' ?
                        <>
                            <p className='font-semibold text-3xl text-black'>${dataSeleted.amount - (dataSeleted.amount / 100) * 3}  {dataSeleted.currency}</p>
                            <p className='text-black text-base'>Recibido USD</p>
                            <p className='font-semibold text-xl text-black'>Para {dataSeleted.first_name} {dataSeleted.last_name}</p>
                            <p className='font-semibold text-black text-base'>ACH Deposit</p>
                            <p className='text-black text-base'>{dataSeleted.application_date}</p>
                            <p className='text-black text-base'>Hecho</p>
                            <p className='text-black text-base font-semibold'>Detalles de la transacción</p>
                            <p className='text-black text-base'>Account Number: ******{dataSeleted.ACH_Account_Number.slice(-4)}</p>
                            <p className='text-black text-base'>Bank: {dataSeleted.bank}</p>
                            <p className='text-black text-base'>Routing Number: {dataSeleted.ACH_Routing_Number}</p>
                            <p className='text-black text-base'>Enviado: ${dataSeleted.amount}</p>
                            <p className='text-black text-base'>Fee: 3% = {(dataSeleted.amount / 100) * 3} USD</p>
                        </>
                        :
                        <>
                            <p className='font-semibold text-3xl text-black'>${dataSeleted.amount_to_send}  {dataSeleted.currency}</p>
                            {
                                dataSeleted.currency === 'USD' ?
                                    <>
                                        <p className='text-black text-base'>Enviado USD</p>
                                        <p className='font-semibold text-xl text-black'>Para {dataSeleted.first_name} {dataSeleted.last_name}</p>
                                        <p className='font-semibold text-black text-base'>ACH Deposit</p>
                                    </>
                                    :
                                    <>
                                        <p className='text-black text-xl'>${dataSeleted.amount} USD</p>
                                        <p className='font-semibold text-xl text-black'>Para {dataSeleted.full_name}</p>
                                        <p className='font-semibold text-black text-base'>SPEI Deposit MXN</p>
                                    </>
                            }
                            <p className='text-black text-base'>{dataSeleted.application_date}</p>
                            {
                                dataSeleted.currency === 'USD' ? <></> : <p className='text-black text-base'>{dataSeleted.concept}</p>
                            }
                            <p className='text-black text-base'>{dataSeleted.status} | {dataSeleted.status === "Finished" ? "Done" : "Not processed"}</p>
                            <p className='text-black text-base font-semibold'>Detalles de la transacción</p>
                            {
                                dataSeleted.currency === 'USD' ?
                                    <>
                                        <p className='text-black text-base'>Account Number: ******{dataSeleted.ACH_Account_Number.slice(-4)}</p>
                                        <p className='text-black text-base'>Routing Number: {dataSeleted.ACH_Routing_Number}</p>
                                        <p className='text-black text-base'>Enviado: ${dataSeleted.amount}</p>
                                    </>
                                    :
                                    <>
                                        <p className='text-black text-base'>CLABE: {dataSeleted.CLABE}</p>
                                        <p className='text-black text-base'>1 USD: {dataSeleted.price_usd} MXN</p>
                                        <p className='text-black text-base'>TO MXN: {dataSeleted.amount_to_send} MXN</p>
                                    </>
                            }
                            <p className='text-black text-base'>FEE: {dataSeleted.fee_amount} USD ({dataSeleted.fee_amount} MXN)</p>
                        </>
                }
            </Modal>
        </div>
    )
}

export default PaymentList; 