import React from "react";
import { Link } from "react-router-dom";
import logoRayo from '../assets/logoRayo.png';
import imgLanding from '../assets/imgLandingv2.png';
import cuentaRayo from '../assets/cuentaRayo.png';
import negociosRayo from '../assets/negociosRayo.png';
import logoVectorImg from '../assets/logoVectorImg.png';
import './Main.css';

const LandingPagev2 = () => {
    return (
<div className="bg-[#131313]">
    <header className="flex items-center justify-between h-20">
        <img className="h-6 ml-4 sm:ml-10" src={logoRayo} alt="Cuenta rayo." />
        <Link
            to="/login"
            className="mr-4 sm:mr-10 bg-[#0054D1] text-white rounded-full h-[40px] px-4 flex items-center justify-center poppins"
        >
            LOG-IN
        </Link>
    </header>
    <section className="flex flex-col sm:flex-row items-center justify-center text-center sm:text-left">
        <div className="flex flex-col items-center sm:items-start my-10 sm:my-20">
            <h1 className="text-white font-bold text-3xl sm:text-5xl inter">
                Revolución financiera
            </h1>
            <p className="text-white font-medium text-xl sm:text-2xl inter mt-2 sm:mt-4">
                Paga, cobra y envía sin fronteras.
            </p>
        </div>
        <img className="h-40 sm:h-64 mt-4 sm:mt-0" src={imgLanding} alt="Imagen." />
    </section>
    <section className="flex items-center justify-center py-6 sm:py-10">
        <p className="text-white text-xs sm:text-sm font-light poppins px-4 sm:px-0 text-center">
            En rayo, nuestra misión es poner las finanzas al servicio de las personas y el mundo, para que puedan alcanzar sus más preciados sueños.
        </p>
    </section>
    <section className="flex flex-col items-center justify-center py-10 sm:py-20">
        <div className="text-center">
            <p className="text-white font-bold text-2xl sm:text-3xl py-2 inter">
                Comprometidos contigo
            </p>
            <p className="text-white text-xs sm:text-sm font-light py-2 opacity-60 poppins">
                Creemos en finanzas cercanas, globales y que te permitan hacer más con tu dinero.
            </p>
        </div>
    </section>
    <section className="grid grid-cols-1 sm:grid-cols-2 py-10 sm:py-20 gap-4">
        <div className="flex flex-col items-center justify-center text-center">
            <p className="text-white font-bold text-2xl sm:text-4xl py-2 sm:py-3 inter">
                Rayo Negocios
            </p>
            <p className="text-white font-medium text-sm sm:text-base py-2 sm:py-3 poppins">
                Haz más con tu dinero.
            </p>
            <p className="text-white text-xs py-2 sm:py-3 poppins">
                Una plataforma para cobrarle a tus clientes en dólares con el mejor tipo de cambio.
            </p>
            <button className="bg-[#0054D1] text-white rounded-full my-3 h-[40px] px-4 poppins">
                Prúebalo
            </button>
        </div>
        <div className="flex flex-col items-center justify-center">
            <img className="h-40 sm:h-g4 ml-0 sm:ml-10" src={negociosRayo} alt="Cuenta rayo." />
        </div>
    </section>
    <section className="grid grid-cols-1 sm:grid-cols-2 py-10 sm:py-20 gap-4">
        <div className="flex flex-col items-center justify-center text-center">
            <p className="text-white font-bold text-2xl sm:text-4xl py-2 sm:py-3 inter">
                Cuenta Rayo
            </p>
            <p className="text-white font-medium text-sm sm:text-base py-2 sm:py-3 poppins">
                Vive global, paga como local.
            </p>
            <p className="text-white text-xs py-2 sm:py-3 text-center poppins">
                Una cuenta para hacer más con tu dinero y estar cerca de los tuyos, no importa<br /> dónde estén.
            </p>
            <p className="text-white text-xs py-2 sm:py-3 opacity-60 poppins">
                ÚNETE A LA LISTA DE ESPERA
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center">
                <input
                    className="bg-[#2C2C2C] w-[250px] sm:w-[280px] h-[40px] border-[1px] border-white rounded-full pl-3 placeholder:text-white text-xs sm:text-sm my-2 sm:my-0 sm:mr-3"
                    type="email"
                    placeholder="Ingresa tu correo electrónico"
                />
                <button className="bg-[#0054D1] text-white rounded-full h-[40px] px-4 poppins my-2 sm:my-0">
                    Prúebalo
                </button>
            </div>
        </div>
        <div className="flex flex-col items-center justify-center">
            <img className="h-40 sm:h-g4 ml-0 sm:ml-10" src={cuentaRayo} alt="Cuenta rayo." />
        </div>
    </section>
    <footer className="flex flex-col items-center pt-10 pb-10">
        <div className="flex justify-center">
            <Link to="/terminosycondiciones" className="text-white mx-2 text-xs poppins">
                Términos y Condiciones
            </Link>
            <Link to="/privacidad" className="text-white mx-2 text-xs poppins">
                Aviso de Privacidad
            </Link>
        </div>
        <img className="h-5 mt-5" src={logoVectorImg} alt="Cuenta rayo." />
    </footer>
</div>

    );
    }
    
export default LandingPagev2;