import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Button, message } from 'antd';
import { useLocation } from "react-router-dom";
import RayoLogo from '../assets/RayoLightning.png';
import Api from '../api/api';

const Pay = () => {
  const api = new Api();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const path = location.pathname.split('/')[2];
    fetchAPI(path);
  }, [location.pathname]);

  const fetchAPI = (reference) => {
    setLoading(true);
    api.getPaymentDetail(reference)
      .then(response => {
        setLoading(false);
        if (response.status === 'ok') {
          setData(response.data);
        } else {
          messageApi.error('Error intenta de nuevo.');
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error: ', error);
        messageApi.error('Error de conexión.');
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        messageApi.success('Copied to clipboard!');
      })
      .catch((error) => {
        console.error('Error copying to clipboard: ', error);
        messageApi.error('Failed to copy.');
      });
  };

  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center bg-gray-100'>
      {contextHolder}
      <Helmet><title>Checkout | Rayo</title></Helmet>
      <div className='bg-white w-full h-full max-w-md flex flex-wrap justify-between' style={{ padding: 30 }}>
        <img src={RayoLogo} alt="Logo Rayo" className="h-10 mx-auto mb-4 d-block" />

        <div className="text-center flex flex-col w-full">
          <h1 className='text-4xl md:text-5xl font-bold text-gray-700'>${data?.payment?.amount} {data?.payment?.currency}</h1>
          <h2 className='text-3xl md:text-5xl font-semibold text-gray-700 mt-3'>{data?.payment?.concept}</h2>
          <p className="mt-4">To {data?.company_name}</p>
        </div>

        <div className="text-center w-full flex flex-col">
          <p className="text-sm">Use your bank account to pay to the merchant with the following details</p>
          <h2 className='text-2xl md:text-3xl font-bold text-gray-600 mt-2'>Deposit Details</h2>

          <div className='bg-gray-200 mb-5 rounded-3xl p-10 mt-5 w-full h-full flex flex-col gap-4 text-start'>
            <div>
              <p className="text-lg font-semibold text-gray-600">Bank</p>
              <div className="w-full flex justify-between items-center mt-2">
                <p>Lead Bank</p>
                <Button onClick={() => copyToClipboard('Lead Bank')} style={{ border: "0.5px solid gray", borderRadius: 10, padding: 10 }} size="small">Copy</Button>
              </div>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-600">Account number</p>
              <div className="w-full flex justify-between items-center mt-2">
                <p>214285344574</p>
                <Button onClick={() => copyToClipboard('214285344574')} style={{ border: "0.5px solid gray", borderRadius: 10, padding: 10 }} size="small">Copy</Button>
              </div>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-600">ABA/Routing number</p>
              <div className="w-full flex justify-between items-center mt-2">
                <p>101019644</p>
                <Button onClick={() => copyToClipboard('101019644')} style={{ border: "0.5px solid gray", borderRadius: 10, padding: 10 }} size="small">Copy</Button>
              </div>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-600">Name of the recipient</p>
              <div className="w-full flex justify-between items-center mt-2">
                <p>José Enrique Soto Vera</p>
                <Button onClick={() => copyToClipboard('José Enrique Soto Vera')} style={{ border: "0.5px solid gray", borderRadius: 10, padding: 10 }} size="small">Copy</Button>
              </div>
            </div>
            <div>
              <p className="text-lg font-semibold text-gray-600">Type of account</p>
              <div className="w-full flex justify-between items-center mt-2">
                <p>Checking Account</p>
                <Button onClick={() => copyToClipboard('Checking Account')} style={{ border: "0.5px solid gray", borderRadius: 10, padding: 10 }} size="small">Copy</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pay;