import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import { Helmet } from "react-helmet";
import { Button, message, Modal, QRCode } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import Api from '../api/api';

const Payments = () => {
    const api = new Api();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const token = localStorage.getItem('token');

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const copyClip = () => {
        navigator.clipboard.writeText(data?.payment?.url);
        messageApi.success('Copiado al portapapeles.');
    }

    function doDownload(url, fileName) {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const downloadCanvasQRCode = () => {
        const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            doDownload(url, 'payment_rayo_' + data?.payment.reference + '.png');
        }
    };

    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [concept, setConcept] = useState('');
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        // eslint-disable-next-line
    }, [])

    const fetchAPI = () => {
        if (amount == 0 || amount >= 15000) {
            messageApi.error('Importe no válido.');
            return;
        }
        setLoading(true);
        const formData = {
            amount,
            concept
        };
        api.createPaymentVT(formData, token)
            .then(respose => {
                setLoading(false);
                if (respose.status === 'ok') {
                    setData(respose.data);
                    showModal(true);
                } else {
                    messageApi.error('Error intenta de nuevo.');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error: ', error);
                messageApi.error('Error de conexión.');
            });

    };

    return (
        <div className='w-screen h-screen'>
            {contextHolder}
            <Helmet><title>Generar cobro | Rayo</title></Helmet>
            <Header />
            <div className=' w-screen h-[calc(100vh-80px)] bg-[#F4F4F4]'>
                <h1 className='text-black font-semibold text-2xl ml-9 pt-7'>Generar cobro</h1>
                <p className='text-black text-sm ml-9 pt-2'>Genere enlaces de pago para sus clientes con el fin de recibir pagos en dólares estadounidenses.</p>
                <div className="flex flex-col mt-5 mb-5 ml-9">
                    <label className="text-sm font-semibold">Escriba el importe</label>
                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setAmount(text.target.value)} placeholder="$0.00" type="number" />
                    <label className='text-xs text-black mt-1'>Límite de transferencia: 15,000 USD</label>
                </div>
                <div className="flex flex-col mt-5 mb-5 ml-9">
                    <label className="text-sm font-semibold">Escriba el concepto de pago</label>
                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setConcept(text.target.value)} placeholder="Concept" type="text" />
                </div>
                <Button shape="round" className='ml-9' onClick={fetchAPI} disabled={loading ? true : false} type="primary">{loading ? <LoadingOutlined /> : 'CONTINUAR'}</Button>
            </div>
            <Modal title="Generar cobro" open={isModalOpen} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }} onOk={handleOk} okText="Cerrar">
                <h1 className='text-black font-semibold text-2xl pt-7'>Generar cobro</h1>
                <p className='text-black text-sm  pt-2'>Comparta el siguiente código QR con su cliente o envíe el enlace para pagar.</p>
                <div id="myqrcode" className='flex justify-center mt-3 mb-3'>
                    <QRCode
                        type="canvas"
                        value={data?.payment?.url}
                        bgColor="#fff"
                        style={{
                            marginBottom: 16,
                        }}
                    />
                </div>
                <div className='flex justify-center mt-3 mb-3'>
                    <Button shape="round" className='mr-1 ml-1' onClick={downloadCanvasQRCode} disabled={loading ? true : false} type="primary">Exportar en imagen</Button>
                    <Button shape="round" className='mr-1 ml-1' onClick={copyClip} disabled={loading ? true : false} type="primary">Copiar link</Button>
                </div>
            </Modal>
        </div>
    );
}

export default Payments;