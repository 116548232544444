import { Button, Checkbox, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { LoadingOutlined, } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Api from '../api/api';

const Withdraw = () => {
  const api = new Api();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const [currency, setCurrency] = useState("MXN");
  const [exchangeRate, setExchangeRate] = useState(0.00);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [data, setData] = useState({});
  const [amountToSend, setAmountToSend] = useState(0.00);
  //USD -> MXN
  const [CLABE, setCLABE] = useState('');
  const [fullName, setFullName] = useState('');
  const [concept, setConcept] = useState('');
  const [amountUSD, setAmountUSD] = useState(0);
  //USD -> USD
  const [ACHRoutingNumber, setACHRoutingNumber] = useState('');
  const [ACHAccountNumber, setACHAccountNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(true);
    api.getDollarPrice(token)
      .then(respose => {
        setLoading(false);
        if (respose.status === 'ok') {
          //Type to change to user
          setExchangeRate(_.round(respose.data.dollar_price.price_usd_spreed, 2) - 0.01);
        } else {
          messageApi.error('Error intenta de nuevo.');
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error: ', error);
        messageApi.error('Error de conexión.');
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api.getHome(token)
      .then(respose => {
        setLoading(false);
        if (respose.status === 'ok') {
          setData(respose.data);
        } else {
          messageApi.error('Error intenta de nuevo.');
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error: ', error);
        messageApi.error('Error de conexión.');
      });
  }, []);

  const cleanInputs = () => {
    setCLABE('');
    setFullName('');
    setConcept('');
    setAmountUSD(0);
    setACHRoutingNumber('');
    setACHAccountNumber('');
    setFirstName('');
    setLastName('');
    setAmountToSend(0.00);
  }

  const fetchRequestWithdraw = (reference) => {
    setLoading(true);
    api.requestWithdraw(token, reference)
      .then(respose => {
        setLoading(false);
        if (respose.status === 'ok') {
          messageApi.success('Su retiro se ha registrado correctamente y se procesará en un momento.');
          setIsModalOpen(false);
        } else {
          messageApi.error('Error intenta de nuevo.');
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error: ', error);
        messageApi.error('Error de conexión.');
      });
  }

  const createWithdraw = () => {
    if (amountUSD < 5) {
      return messageApi.error('El importe a retirar no puede ser inferior a 5 USD.');
    }
    if (amountUSD > 15000) {
      messageApi.error('No tienes suficiente balance.');
    }
    if (data?.balance < amountUSD) {
      messageApi.error('El importe a retirar no puede superar los 15.000 USD.');
    }
    else {
      let formData = {};
      if (currency === "MXN") {
        if (CLABE.length === 0 || CLABE.length !== 18 || fullName.length === 0 || concept.length === 0) {
          messageApi.error('Todos los campos son obligatorios.');
          return
        }
        formData = {
          "amount": amountUSD,
          "amount_to_send": amountToSend,
          "price_usd": exchangeRate,
          "currency": currency,
          "CLABE": CLABE,
          "full_name": fullName,
          "concept": concept
        }
      } else {
        if (ACHAccountNumber.length === 0 || ACHAccountNumber.length !== 12 || ACHRoutingNumber.length !== 9 || ACHRoutingNumber.length === 0 || firstName.length === 0 || lastName.length === 0) {
          messageApi.error('Todos los campos son obligatorios.');
          return
        }
        formData = {
          "amount": amountUSD,
          "amount_to_send": amountUSD,
          "price_usd": exchangeRate,
          "currency": currency,
          "ACH_Routing_Number": ACHRoutingNumber,
          "ACH_Account_Number": ACHAccountNumber,
          "first_name": firstName,
          "last_name": lastName
        }
      }

      setLoading(true);
      api.createWithdraw(formData, token)
        .then(respose => {
          setLoading(false);
          if (respose.status === 'ok') {
            setModalData(respose.data);
            cleanInputs();
            showModal();
          } else {
            messageApi.error('Error intenta de nuevo.');
          }
        })
        .catch(error => {
          setLoading(false);
          console.error('Error: ', error);
          messageApi.error('Error de conexión.');
        });
    }
  }

  const calculareAmountToSend = (text) => {
    setAmountUSD(text.target.value);
    let amount = text.target.value * exchangeRate;
    setAmountToSend(_.round(amount, 2));
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    fetchRequestWithdraw(modalData.withdraw.reference);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCLABEChange = (event) => {
    const input = event.target.value;

    if (input.length <= 18) {
      setCLABE(input);
    }
  };

  const handleACHAccountChange = (event) => {
    const input = event.target.value;

    if (input.length <= 12) {
      setACHAccountNumber(input);
    }
  };

  const handleACHRoutingChange = (event) => {
    const input = event.target.value;

    if (input.length <= 9) {
      setACHRoutingNumber(input);
    }
  };

  return (
    <div className='w-screen h-screen'>
      {contextHolder}
      <Helmet><title>Retirar | Rayo</title></Helmet>
      <Header />
      <div className='w-screen h-[calc(100vh-80px)] bg-[#F4F4F4] overflow-auto  px-10 flex flex-col gap-5 p-5'>
        <h1 className='text-black font-semibold text-3xl pt-7'>Retirar</h1>
        <div>
          <p className="text-black font-bold">Disponible</p>
          <p>${data?.balance} {data?.currency}</p>
        </div>
        <div>
          <p className="text-black font-bold">USD/MXN</p>
          <p>$1 USD = ${exchangeRate.toFixed(2)} MXN</p>
        </div>
        <p>Puedes retirar tu dinero en USD o MXN y generalmente se tarda 15 minutos en procesar la transferencia.</p>
        <div>
          <p className="font-bold mb-2">Seleccione la divisa que desea retirar</p>
          <Checkbox
            checked={currency === "MXN"}
            onChange={(e) => {
              setCurrency("MXN");
            }}
          >MXN</Checkbox>
          <Checkbox
            checked={currency === "USD"}
            onChange={(e) => {
              setCurrency("USD");
            }}
          >USD</Checkbox>
        </div>
        {
          currency === "MXN" ?
            <>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba la CLABE</label>
                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" value={CLABE} onChange={(text) => handleCLABEChange(text)} placeholder="Escriba la CLABE" type="number" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba el nombre legal completo del destinatario</label>
                <input pattern="[A-Za-z]*" className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={fullName} onChange={(text) => setFullName(text.target.value)} placeholder="Escriba el nombre legal completo del destinatario" type="text" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba el concepto de la transferencia</label>
                <input pattern="[A-Za-z]*" className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={concept} onChange={(text) => setConcept(text.target.value)} placeholder="Escriba el concepto de la transferencia" type="text" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba la cantidad a retirar en USD</label>
                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={amountUSD} onChange={(text) => calculareAmountToSend(text)} placeholder="Escriba la cantidad a retirar en USD" type="number" />
              </div>
              <p className="text-sm text-gray-600 mt-2">Igual a: ${amountToSend.toFixed(2)}</p>
            </> :
            <>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba el ACH Routing Number</label>
                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={ACHRoutingNumber} onChange={(text) => handleACHRoutingChange(text)} placeholder="Escriba el ACH Routing Number" type="number" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba el ACH Account Number</label>
                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={ACHAccountNumber} onChange={(text) => handleACHAccountChange(text)} placeholder="Escriba el ACH Account Number" type="number" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba el nombre del destinatario</label>
                <input pattern="[A-Za-z]*" className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" value={firstName} onChange={(text) => setFirstName(text.target.value)} placeholder="Escriba el nombre del destinatario" type="text" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba el apellido del destinatario</label>
                <input pattern="[A-Za-z]*" className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={lastName} onChange={(text) => setLastName(text.target.value)} placeholder="Escriba el apellido del destinatario" type="text" />
              </div>
              <div className="flex flex-col mt-1 mb-1">
                <label className="text-sm font-semibold">Escriba la cantidad a retirar en USD</label>
                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" value={amountUSD} onChange={(text) => calculareAmountToSend(text)} placeholder="Escriba la cantidad a retirar en USD" type="number" />
              </div>
            </>
        }
        <p>Límite de transferencia: 15.000 USD</p>
        <div>
          <Button shape="round" onClick={createWithdraw} style={{ padding: "0 40px" }} type="primary">{loading ? <LoadingOutlined /> : "CONTINUE"}</Button>
        </div>
      </div>
      <Modal title="Transaction Details" open={isModalOpen} maskClosable={false} okText="TRANSFER" onOk={handleOk} onCancel={handleCancel}>
        <p className='text-black text-sm font-semibold'>Concepto</p>
        <p className='text-black text-sm'>{modalData?.withdraw?.concept}</p>
        <p className='text-black text-sm font-semibold'>Destinatario</p>
        {modalData?.withdraw?.currency === "MXN" ?
          <>
            <p className='text-black text-sm'>{modalData?.withdraw?.full_name}</p>
            <p className='text-black text-sm'>{modalData?.withdraw?.CLABE}</p>
            <p className='text-black text-sm font-semibold'>Monto en MXN</p>
            <p className='text-black text-sm'>${(modalData?.withdraw?.price_usd * modalData?.withdraw?.amount).toFixed(2)}</p>
          </> : <>
            <p className='text-black text-sm'>{modalData?.withdraw?.first_name + ' ' + modalData?.withdraw?.last_name}</p>
            <p className='text-black text-sm'>{modalData?.withdraw?.ACH_Routing_Number + ' / ' + modalData?.withdraw?.ACH_Account_Number}</p>
          </>
        }
        <p className='text-black text-sm font-semibold'>Fees</p>
        <p className='text-black text-sm'>${(modalData?.withdraw?.fee_amount)}</p>
        <p className='text-black text-sm font-semibold'>Tu retiro</p>
        {
          modalData?.withdraw?.currency === "MXN" ?
            <p className='text-black text-sm'>${(modalData?.withdraw?.price_usd * modalData?.withdraw?.amount - modalData?.withdraw?.fee_amount).toFixed(2)}</p>
            :
            <p className='text-black text-sm'>${(modalData?.withdraw?.amount - modalData?.withdraw?.fee_amount).toFixed(2)}</p>
        }
        <p className='text-black text-sm  pt-2'>Al hacer clic en "TRANSFERIR", acepta las condiciones de la transferencia.</p>
      </Modal>
    </div >
  )
}

export default Withdraw 