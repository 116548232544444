import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LandingPage from './pages/LadingPage';
import LandingPagev2 from './pages/LandingPagev2';
import Login from './pages/Login';
import Account from './pages/Home';
import Payments from './pages/Payments';
import Pay from './pages/Pay';
import Withdraw from './pages/Withdraw';
import PaymentList from './pages/PaymentList';
import Register from './pages/Register';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPagev2 />,
    },
    {
      path: "/negocios",
      element: <LandingPage />,
    },
    {
      path: "/pay/:reference",
      element: <Pay />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/account",
      element: <Account />,
    },
    {
      path: "/generate_payment",
      element: <Payments />,
    },
    {
      path: "/withdraw",
      element: <Withdraw />
    },
    {
      path: "/payments",
      element: <PaymentList />
    },
  ]);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
