const base = 'https://api.rayo.la/api';

export default class Api {
    constructor() {
        this.http = new Http()
    }

    login(body) {
        return this.http.post('v1/login', body);
    }

    register(body) {
        return this.http.post('v1/register_draft', body);
    }

    forgotPassword(body) {
        return this.http.post('v1/forgot_password', body);
    }

    resetPassword(body) {
        return this.http.post('v1/reset_password', body);
    }

    getHome(token) {
        return this.http.get('v1/get_home', token);
    }

    createPaymentVT(body, token) {
        return this.http.post('v1/payment_vt', body, token);
    }

    getPaymentListVt(token) {
        return this.http.get('v1/payment_vt/list', token);
    }

    getDollarPrice(token) {
        return this.http.get('v1/payment_vt/usd_mxn', token);
    }

    createWithdraw(body, token) {
        return this.http.post('v1/payment_vt/withdraw', body, token);
    }

    requestWithdraw(token, reference) {
        return this.http.get('v1/payment_vt/withdraw/request/' + reference, token);
    }

    getPaymentDetail(reference) {
        return this.http.get(`v1/payment_pay/${reference}`);
    }

    getAccount(token) {
        return this.http.get('v1/get_account', token);
    }

    getContacts(token) {
        return this.http.get('v1/get_contacts', token);
    }

    getContactSimple(id, token) {
        return this.http.get(`v1/get_contact_simple/${id}`, token);
    }

    searchContact(body, token) {
        return this.http.post('v1/search_contact', body, token);
    }

    getBalanceSimple(token) {
        return this.http.get('v1/get_balance_simple', token);
    }

    startTRS(body, token) {
        return this.http.post('v1/start_TRS', body, token);
    }

    processTRS(body, token) {
        return this.http.post('v1/process_TRS', body, token);
    }

    endTRS(body, token) {
        return this.http.post('v1/end_TRS', body, token);
    }

    getWithdraws(token) {
        return this.http.get('v1/get_withdrawals', token);
    }

    getMovements(token) {
        return this.http.get('v1/get_movements', token);
    }

    createdPayment(body, token) {
        return this.http.post('v1/created_payment', body, token);
    }

    fastPay(body, token) {
        return this.http.post('v1/fast_pay', body, token);
    }

    fastPaySettings(body, token) {
        return this.http.post('v1/setting_payment', body, token);
    }

    getWaitListBot(token) {
        return this.http.get('v1/get_wl_bot', token);
    }

    setWaitListBot(token) {
        return this.http.get('v1/set_wl_bot', token);
    }

}

class Http {
    get(url, token = '') {
        return fetch(`${base}/${url}`, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                authorization: token ? `Bearer ${token}` : '',
            },
        })
            .then(res => {
                return res.json()
            })
            .catch(err => console.warn('Http:err', err.message))
    }

    post(url, body, token = '') {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
        }
        if (token) {
            headers.authorization = `Bearer ${token}`
        }
        return fetch(`${base}/${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
        })
            .then(res => {
                return res.text()
            })
            .then(res => {
                return JSON.parse(res)
            })
            .catch(err => console.log('Http:err', err.message))
    }

    postBloob(url, body, token = '') {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
        }
        if (token) {
            headers.authorization = `Bearer ${token}`
        }
        return fetch(`${base}/${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers,
        })
            .then(res => {
                return res.blob()
            })
            .catch(err => console.warn('Http:err', err.message))
    }

    postMultipart(url, body, token = '') {
        const headers = {
        }
        if (token) {
            headers.authorization = `Bearer ${token}`
        }
        return fetch(`${base}/${url}`, {
            method: 'POST',
            body: body,
            headers,
        })
            .then(res => {
                return res.json()
            })
            .catch(err => console.warn('Http:err', err.message))
    }
}