import React, { useState, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import { Helmet } from "react-helmet";
import { LoadingOutlined, } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import Api from '../api/api';
import RayoLogo from '../assets/RayoLightning.png';

const Login = () => {
    const api = new Api();
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [emailReset, setEmailReset] = useState('');
    const [passwordReset, setPasswordReset] = useState('');
    const [code, setCode] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate("/account");
        }
    }, [navigate]);

    const sendEmailAPI = () => {
        if (emailReset.length > 0) {
            setLoading(true)
            const formData = {
                email: emailReset
            };

            api.forgotPassword(formData)
                .then(respose => {
                    setLoading(false)
                    if (respose.status === 'ok') {
                        setStep(1);
                    } else {
                        messageApi.error('Error al enviar el correo electronico.');
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.log(`Error: ${error}`)
                });
        }
        else {
            messageApi.error('Ingrese un correo valido.');
        }
    };

    const sendResetAPI = () => {
        if (passwordReset.length > 0 && code.length > 0) {
            setLoading(true);

            const formData = {
                passwordNew: passwordReset,
                passwordNewConfirm: passwordReset,
                code: code,
                email: emailReset
            };

            api.resetPassword(formData)
                .then(respose => {
                    setLoading(false)
                    if (respose.status === 'ok') {
                        messageApi.success('Contraseña actualizada correctamente.');
                        setIsModalOpen(false);
                    } else {
                        messageApi.error('El codigo enviado no es valido.');
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.log(`Error: ${error}`)
                });
        }
        else {
            messageApi.error('Ingrese los valores validos.');
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        if (step === 0) {
            sendEmailAPI();
        } else {
            sendResetAPI();
        }

    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sendLoginAPI = () => {
        if (email.length > 0 && password.length > 0) {
            setLoading(true);
            const formData = {
                email,
                password
            };
            api.login(formData)
                .then(respose => {
                    setLoading(false);
                    if (respose.status === 'ok') {
                        localStorage.setItem('token', respose.data.token);
                        localStorage.setItem('userName', respose.data.user.name);
                        localStorage.setItem('userEmail', respose.data.user.email);
                        navigate("/account");
                    } else {
                        messageApi.error('Email o contraseña incorrecta.');
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error('Error: ', error);
                    messageApi.error('Error de conexión.');
                });
        } else {
            messageApi.error('Ingrese un correo y una contraseña valida.');
        }
    };

    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <Helmet><title>Login | Rayo</title></Helmet>
            {contextHolder}
            <div>
                <div className="flex flex-col justify-center items-center mt-5 mb-5">
                    <img src={RayoLogo} alt="Logo Rayo" className="h-12 mt-5 mb-5" />
                    <h1 className="text-3xl font-semibold mt-5 mb-5">Bienvenido</h1>
                    <h2 className="text-xl font-semibold mt-5 mb-5">Login</h2>
                </div>
                <div className="flex flex-col mt-5 mb-5">
                    <label className="text-sm">Email</label>
                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" onChange={(text) => setEmail(text.target.value)} placeholder="email@email.com" type="email" />
                </div>
                <div className="flex flex-col mt-5 mb-5">
                    <label className="text-sm">Contraseña</label>
                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setPassword(text.target.value)} placeholder="********" type="password" />
                </div>
                <div className="flex justify-center items-center mt-10 mb-10">
                    <Button onClick={sendLoginAPI} shape="round" disabled={loading ? true : false} type="primary">{loading ? <LoadingOutlined /> : 'Ingresar'}</Button>
                </div>
                <div className="flex justify-center items-center mt-10 mb-10">
                    <Button onClick={showModal} type="text">¿Olvidaste tu contraseña?</Button>
                </div>
            </div>
            <Modal title="Restablecer contraseña" open={isModalOpen} onOk={handleOk} okText='Continuar' onCancel={handleCancel}>
                {step === 0 ?
                    <>
                        <p>Ingresa tu email, enviaremos un codigo para actualizar tu contraseña.</p>
                        <div className="flex flex-col mt-5 mb-5">
                            <label className="text-sm">Email</label>
                            <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" onChange={(text) => setEmailReset(text.target.value)} placeholder="email@email.com" type="email" />
                        </div>
                    </>
                    : <>
                        <p>Ingresa el codigo que te enviamos a tu correo y tu nueva contraseña.</p>
                        <div className="flex flex-col mt-5 mb-5">
                            <label className="text-sm">Codigo</label>
                            <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" onChange={(text) => setCode(text.target.value)} placeholder="" type="text" />
                        </div>
                        <div className="flex flex-col mt-5 mb-5">
                            <label className="text-sm">Nueva contraseña</label>
                            <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1  pl-3" onChange={(text) => setPasswordReset(text.target.value)} placeholder="" type="password" />
                        </div>
                    </>
                }
            </Modal>
        </div>
    );
}

export default Login;