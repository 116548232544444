import React, { useState, useEffect } from "react";
import { Button, message, Steps } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Api from '../api/api';

import RayoLogo from '../assets/RayoLightning.png';

const Register = () => {
    const api = new Api();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [lastname_f, setLastnameF] = useState('');
    const [lastname_m, setLastnameM] = useState('');
    const [phone, setPhone] = useState('');
    const [username, setUsername] = useState('');
    const [legalName, setLegalName] = useState('');
    const [rfc, setRfc] = useState('');
    const [current, setCurrent] = useState(0);

    const fetchRegister = () => {
        setLoading(true);
        const formData = {
            name,
            lastname_f,
            lastname_m,
            email,
            phone,
            contry_code: '+52',
            password,
            birth_date: '1990-01-01',
            gender: 'H',
            nip: '1234',
            username,
            citizenship: 'USA',
            type: 'Company',
            legal_name: legalName,
            RFC: rfc
        };

        api.register(formData)
            .then(respose => {
                setLoading(false)
                if (respose.status === 'Success') {
                    localStorage.setItem('token', respose.data.token);
                    localStorage.setItem('userName', respose.data.user.name);
                    localStorage.setItem('userEmail', respose.data.user.email);
                    navigate("/account");
                } else {
                    console.log(respose)
                    messageApi.error('Error al registrar usuario');
                }
            })
            .catch(error => {
                setLoading(false)
                console.log(`Error: ${error}`)
            });
    }


    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate("/account");
        }
    }, [navigate]);

    const next = () => {
        if (current === 0) {
            if (email === '' || phone === '') {
                message.error('Debes llenar todos los campos');
                return;
            }
            else {
                setCurrent(current + 1);
            }
        }
        if (current === 1) {
            if (name === '' || lastname_f === '' || lastname_m === '') {
                message.error('Debes llenar todos los campos');
                return;
            }
            else {
                setCurrent(current + 1);
            }
        }
        if (current === 2) {
            if (username === '' || password === '') {
                message.error('Debes llenar todos los campos');
                return;
            }
            else {
                setCurrent(current + 1);
            }
        }
        if (current === 3) {
            if (legalName === '' || rfc === '') {
                message.error('Debes llenar todos los campos');
                return;
            }
            else {
                setCurrent(current + 1);
            }
        }
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Iniciemos',
            content: 'Ingresa tu correo y tu número de teléfono.',
        },
        {
            title: 'Segundo',
            content: 'Ingresa tu nombre y apellidos.',
        },
        {
            title: 'Tercero',
            content: 'Ingresa tu nombre de usuario y contraseña.',
        },
        {
            title: 'Por último',
            content: 'Ingresa el nombre de tu empresa y tu RFC.',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    return (
        <div className="bg-white w-screen h-screen flex flex-col justify-center items-center">
            <Helmet><title>Registro | Rayo</title></Helmet>
            {contextHolder}
            <div className="flex flex-col justify-center items-center">
                <img src={RayoLogo} alt="Logo Rayo" className="h-12 mt-5 mb-5" />
                <h1 className="text-3xl font-semibold">Bienvenido</h1>
                <h2 className="text-xl font-semibold ">Crear una cuenta</h2>
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center">
                <Steps current={current} items={items} />
                <div className="w-1/2">
                    {current === 0 ?
                        <div className="w-4/5 mt-3">
                            <div className="flex flex-col mt-5 mb-5 ml-9">
                                <label className="text-sm font-semibold">Ingresa tu email</label>
                                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setEmail(text.target.value)} value={email} placeholder="email@email.com" type="email" />
                            </div>
                            <div className="flex flex-col mt-5 mb-5 ml-9">
                                <label className="text-sm font-semibold">Ingresa tu celular</label>
                                <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setPhone(text.target.value)} value={phone} placeholder="+520000000000" type="phone" />
                            </div>
                        </div>
                        : current === 1 ?
                            <div className="w-4/5 mt-3">
                                <div className="flex flex-col mt-5 mb-5 ml-9">
                                    <label className="text-sm font-semibold">Ingresa tu nombre</label>
                                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setName(text.target.value)} value={name} placeholder="Ingresa tu nombre" type="text" />
                                </div>
                                <div className="flex flex-col mt-5 mb-5 ml-9">
                                    <label className="text-sm font-semibold">Ingresa tu apellido paterno</label>
                                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setLastnameF(text.target.value)} value={lastname_f} placeholder="Ingresa tu apellido paterno" type="text" />
                                </div>
                                <div className="flex flex-col mt-5 mb-5 ml-9">
                                    <label className="text-sm font-semibold">Ingresa tu apellido materno</label>
                                    <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setLastnameM(text.target.value)} value={lastname_m} placeholder="Ingresa tu apellido materno" type="text" />
                                </div>
                            </div>
                            : current === 2 ?
                                <div className="w-4/5 mt-3">
                                    <div className="flex flex-col mt-5 mb-5 ml-9">
                                        <label className="text-sm font-semibold">Ingresa tu nombre de usuario</label>
                                        <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setUsername(text.target.value)} value={username} placeholder="Ingresa tu nombre de usuario" type="text" />
                                    </div>
                                    <div className="flex flex-col mt-5 mb-5 ml-9">
                                        <label className="text-sm font-semibold">Ingresa tu contraseña</label>
                                        <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setPassword(text.target.value)} value={password} placeholder="**********" type="password" />
                                    </div>
                                </div>
                                : <div className="w-4/5 mt-3">
                                    <div className="flex flex-col mt-5 mb-5 ml-9">
                                        <label className="text-sm font-semibold">Ingresa el nombre de tu empresa</label>
                                        <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setLegalName(text.target.value)} value={legalName} placeholder="Ingresa el nombre de tu empresa" type="text" />
                                    </div>
                                    <div className="flex flex-col mt-5 mb-5 ml-9">
                                        <label className="text-sm font-semibold">Ingresa tu RFC</label>
                                        <input className="w-80 h-10 bg-[#E8E8E8] rounded-2xl mt-1 pl-3" onChange={(text) => setRfc(text.target.value)} value={rfc} placeholder="Ingresa tu RFC" type="text" />
                                    </div>
                                </div>}
                </div>
                <div
                    style={{
                        marginTop: 24,
                    }}
                >
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Continuar
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => fetchRegister()}>
                            {loading ? <LoadingOutlined /> : 'Registrate'}
                        </Button>
                    )}
                    {current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Anterior
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Register;