import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RayoLogo from '../assets/RayoLightning.png';
import { Avatar, Dropdown, Drawer, Menu, Button } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [path, setPath] = useState('');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const userEmail = localStorage.getItem('userEmail');
    const userName = localStorage.getItem('userEmail')?.split('')[0].toUpperCase();

    useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);

    const items = [
        {
            key: '0',
            label: (
                <p>Email: {userEmail}</p>
            ),
            disabled: true,
        },
        {
            key: '1',
            danger: true,
            label: 'Cerrar sesión',
            onClick: () => navLogOut()
        },
    ];

    const navLogOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        navigate("/");
    };

    const toggleDrawer = () => {
        setDrawerVisible(!drawerVisible);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const menuItems = (
        <>
            <Link className={path === "/account" ? "text-base text-[#0054D1] hover:text-[#0054D1] ml-2 mr-2" : "text-base text-[#7B7B7B] hover:text-[#0054D1] ml-2 mr-2"} to='/account'>Mi cuenta</Link>
            <Link className={path === "/generate_payment" ? "text-base text-[#0054D1] hover:text-[#0054D1] ml-2 mr-2" : "text-base text-[#7B7B7B] hover:text-[#0054D1] ml-2 mr-2"} to='/generate_payment'>Generar cobro</Link>
            <Link className={path === "/payments" ? "text-base text-[#0054D1] hover:text-[#0054D1] ml-2 mr-2" : "text-base text-[#7B7B7B] hover:text-[#0054D1] ml-2 mr-2"} to='/payments'>Transferencias</Link>
            <Link className={path === "/withdraw" ? "text-base text-[#0054D1] hover:text-[#0054D1] ml-2 mr-2" : "text-base text-[#7B7B7B] hover:text-[#0054D1] ml-2 mr-2"} to='/withdraw'>Retirar</Link>
        </>
    );

    return (
        <header className="w-screen h-20 flex">
            <div className="w-1/2 h-full content-center flex">
                <div className="h-full flex items-center justify-center">
                    <img src={RayoLogo} alt="Logo Rayo" className="h-10 ml-10" />
                </div>
                <div className="w-auto h-full hidden md:flex justify-center items-center ml-5">
                    {menuItems}
                </div>
            </div>
            <div className="w-1/2 h-full flex justify-end items-center mr-5">
                <Dropdown
                    menu={{
                        items,
                    }}
                >
                    <Avatar className="mr-4 bg-black text-white font-semibold" size="large">
                        {userName}
                    </Avatar>
                </Dropdown>
                <Button className="md:hidden" icon={<MenuOutlined />} onClick={toggleDrawer} />
            </div>
            <Drawer
                title="Menu"
                placement="right"
                onClose={closeDrawer}
                visible={drawerVisible}
            >
                <Menu>
                    {menuItems}
                </Menu>
            </Drawer>
        </header>
    );
};

export default Header;