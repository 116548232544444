import React from 'react';
import { Link } from "react-router-dom";

import logoRayo from '../assets/logoRayo.png';
import heroImg from '../assets/heroImg.png';
import sec1Img from '../assets/sec1Img.png';
import sec2Img from '../assets/sec2Img.png';
import suportImg from '../assets/suportImg.png';
import logoVectorImg from '../assets/logoVectorImg.png';
import preson1 from '../assets/Ellipse6.png';
import preson2 from '../assets/Ellipse7.png';
import preson3 from '../assets/Ellipse8.png';

function LandingPage() {
    return (
        <div className="w-screen bg-black">
            <header className='flex content-center items-center justify-between	h-20'>
                <img className='h-6 ml-10' src={logoRayo} alt='Cuenta rayo.' />
                <div>
                <Link className='bg-[#0054D1] py-2 px-7 rounded-3xl mr-10 text-white' to='/login'>Login</Link>
                <Link className='bg-[#0054D1] py-2 px-7 rounded-3xl mr-10 text-white' to='/register'>Registrate</Link>
                </div>
            </header>
            <section className="container mx-auto">
                <div class="flex justify-between items-center py-10">
                    <div class="text-left">
                        <h1 className='text-white font-bold	text-6xl'>Cuenta Rayo</h1>
                        <p className='text-white text-lg'>
                            Envía dinero a México y recibe de los tuyos de una forma<br />práctica, segura y barata.
                        </p>
                    </div>
                    <div class="w-1/2 ">
                        <img className='h-70' src={heroImg} alt='Cuenta rayo.' />
                    </div>
                </div>
            </section>
            {
                //Sec Text
            }
            <section className='flex flex-col content-center items-center bg-black pt-20 pb-20'>
                <h1 className='text-white text-5xl font-bold	'>Enviar dinero de Estados Unidos a México</h1>
                <p className='text-white text-2xl mt-5 opacity-60'>Hoy en día, enviar dinero desde allá te sigue saliendo<br /> caro y tardado. Con Rayo esto quedó en el pasado.</p>
            </section>
            {
                //Sec Feature 1
            }
            <section className="w-screen bg-black flex content-center justify-center mt-10 mb-10">
                <div className='flex flex-row'>
                    <div className='flex flex-col justify-center text-right	'>
                        <h1 className='text-white font-bold text-2xl'>Mejor tipo de cambio<br />Al instante<br />Primera transferencia gratis</h1>
                        <p className='text-white text-lg mt-2'>Después, 3 USD por operación</p>
                        <p className='text-white text-xs'>Límite mensual de 1,000 USD</p>
                    </div>
                    <img className='h-30' src={sec1Img} alt='Cuenta rayo.' />
                </div>
            </section>
            {
                //Sec feature 2
            }
            <section className="w-screen bg-black flex content-center justify-center mt-10 mb-10">
                <div className='flex flex-row'>
                    <img className='h-30' src={sec2Img} alt='Cuenta rayo.' />
                    <div className='flex flex-col justify-center text-left	'>
                        <h1 className='text-white font-bold text-2xl'>Tu seguridad es primero</h1>
                        <p className='text-white text-lg mt-2'>En rayo proteguemos tus datos<br />con la mayor seguridad utilizando IA Y ML</p>
                    </div>
                </div>
            </section>
            {
                //Sec New
            }
            <section className='flex flex-col content-center items-center bg-black pt-20 pb-20'>
                <h1 className='text-[#CCB4FF] text-2xl opacity-70'>TESTIMONIOS</h1>
                <p className='text-white text-5xl mt-5 text-center'>Lee lo que otras personas<br />dicen de nosotros</p>
                <div className='mt-5 flex'>
                    <div className='bg-[#0D1E4E] w-[280px] h-[250px] flex flex-col content-center items-center justify-center rounded-xl m-5'>
                        <img className='h-20 translate-y-[-50%]' src={preson1} alt='Cuenta rayo.' />
                        <p className='text-white text-lg'>HECTOR MAZA</p>
                        <p className='text-white text-sm mt-3	text-center'>Rayo me cambió la vida. Siempre<br />desconfié de los bancos pero<br />ahora con Rayo tengo todo lo que<br />necesito y envío dinero de forma<br />fácil y sencilla.</p>
                    </div>
                    <div className='bg-[#0D1E4E] w-[280px] h-[250px] flex flex-col content-center items-center justify-center rounded-xl m-5'>
                        <img className='h-20 translate-y-[-50%]' src={preson3} alt='Cuenta rayo.' />
                        <p className='text-white text-lg'>ANDREA PÉREZ</p>
                        <p className='text-white text-sm mt-3	text-center'>Enviar dinero a mi familia ha sido<br />un dolor de cabeza y que siempre<br />es caro. Hoy con Rayo la historia es<br />diferente. 100/10.</p>
                    </div>
                    <div className='bg-[#0D1E4E] w-[280px] h-[250px] flex flex-col content-center items-center justify-center rounded-xl m-5'>
                        <img className='h-20 translate-y-[-50%]' src={preson2} alt='Cuenta rayo.' />
                        <p className='text-white text-lg'>ANTONIO CISNEROS</p>
                        <p className='text-white text-sm mt-3	text-center'>Pocas empresas tienen la atención<br />que tiene Rayo. No sólo para enviar<br />dinero sino también con todos sus<br />talleres y asesorías que tienen para ti. </p>
                    </div>
                </div>
            </section>
            {
                //Sec Text
            }
            <section className='flex flex-col content-center items text-center bg-black pt-20 pb-20'>
                <h1 className='text-white text-2xl font-bold'>Sobre Rayo</h1>
                <p className='text-white text-center mt-5'>
                    Nadie nace sabiendo cómo administrar su dinero, y hoy en día el dinero para muchos es estrés.<br />
                    Queremos cambiar eso.
                </p>
                <p className='text-white text-center mt-5'>
                    Buscamos ayudar a las personas a que mejoren su relación con el dinero y que vivan mejor y más felices.<br /> Es por eso que estamos para ayudarte.
                </p>
            </section>
            {
                //List
            }
            <section className='flex flex-col content-center items-center bg-black pt-10 pb-10'>
                <h1 className='text-white text-2xl font-bold'>Obtén más información</h1>
                <div className='mt-5'>
                    <input className='bg-[#2C2C2C] w-[280px] h-[40px] border-[1px] border-white rounded-full	pl-3 placeholder:text-white text-sm ' type='email' placeholder='Ingresa tu correo electrónico' />
                    <button className='w-[120px] h-[40px] bg-[#0054D1] rounded-full ml-3 text-white text-sm border-[1px] border-white'>Enviar</button>
                </div>
            </section>
            {
                //Apoyo
            }
            <section className='flex flex-col content-center items-center bg-black pt-10 pb-10'>
                <h1 className='text-white text-2xl font-bold'>Con el apoyo de:</h1>
                <img className='h-14 mt-5' src={suportImg} alt='Cuenta rayo.' />
            </section>
            {
                //Last
            }
            <footer className='flex flex-col content-center items-center bg-black pt-10 pb-10'>
                <div>
                    <a href='https://www.rayo.lol/T&C' className='text-white mr-5 text-xs'>Términos y Condiciones</a>
                    <a href='https://www.rayo.lol/PrivacyNotice' className='text-white ml-5 text-xs'>Aviso de Privacidad</a>
                </div>
                <img className='h-5 mt-5' src={logoVectorImg} alt='Cuenta rayo.' />
            </footer>

        </div>
    );
}

export default LandingPage;