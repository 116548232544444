import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import { Helmet } from "react-helmet";
import { message } from 'antd';
import { LoadingOutlined, } from '@ant-design/icons';
import Api from '../api/api';

const Home = () => {
    const api = new Api();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const token = localStorage.getItem('token');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        else {
            fetchAPI(token);
        }
        // eslint-disable-next-line
    }, [])

    const fetchAPI = (token) => {
        setLoading(true);
        api.getHome(token)
            .then(respose => {
                setLoading(false);
                if (respose.status === 'ok') {
                    setData(respose.data);
                } else {
                    messageApi.error('Error intenta de nuevo.');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error: ', error);
                messageApi.error('Error de conexión.');
            });

    };

    return (
        <div className='w-screen h-screen'>
            {contextHolder}
            <Helmet><title>Mi cuenta | Rayo</title></Helmet>
            <Header />
            <div className=' w-screen h-[calc(100vh-80px)] bg-[#F4F4F4]'>
                <h1 className='text-black font-semibold text-2xl ml-9 pt-7'>Mi cuenta</h1>
                <div className='bg-white w-80 h-32 rounded-3xl ml-9 mt-7 content-center pl-4'>

                    {
                        loading ? <LoadingOutlined /> :
                            <>
                                <h4 className='text-black text-xs'>{data?.currency === 'MXN' ? 'MXN Pesos' : 'US Dollars'}</h4>
                                <h2 className='text-black text-2xl font-semibold'>${data?.balance} {data?.currency}</h2>
                            </>
                    }
                </div>
                <button onClick={() => navigate("/generate_payment")} className='bg-black text-white mt-7 ml-9 rounded-3xl px-5 py-2 hover:bg-slate-700'>Generar cobro</button>
            </div>
        </div>
    );
}

export default Home;